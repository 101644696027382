import { Babies } from 'common/elements/babies';
import { CloseButton } from 'common/elements/close-button';
import { Policy } from 'common/elements/policy';
import { DatePicker } from 'common/form/date-picker';
import { Input } from 'common/form/input';
import { Select } from 'common/form/select';
import { useAppContext } from 'features/app';
import { AppActions } from 'features/app/contexts/app-context/types';
import { useBookingContext } from 'features/booking/contexts/booking-context';
import { BookingActions } from 'features/booking/contexts/booking-context/types';
import { FormEvent, MouseEvent } from 'react';
import { getAdultOptions, getBabyOptions } from 'utils/booking';
import styles from './styles.module.scss';
import { IProps } from './types';

export function BookingBody({ onClose }: IProps) {
  const [, appDispatch] = useAppContext();
  const [state, dispatch] = useBookingContext();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (state.arrival === null) {
      dispatch({ type: BookingActions.ToggleArrival });
      return;
    }
    if (state.departure === null) {
      dispatch({ type: BookingActions.ToggleDeparture });
      return;
    }

    // ym(87397023, 'reachGoal', 'kick');
    // gtag('event', 'submit', { event_category: 'form' });

    dispatch({ type: BookingActions.ToggleModal });
    setTimeout(
      () => appDispatch({ type: AppActions.ToggleThanks, payload: 'request' }),
      300
    );

    await fetch('/ajax/_forms.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        form: state.type === 'booking' ? 'Бронирование' : 'Расчет стоимости',
        room: state.room ?? '',
        arrival: state.arrival.toLocaleDateString(),
        departure: state.departure.toLocaleDateString(),
        adults: state.adults,
        babies: state.babies,
        name: state.name,
        phone: state.phone,
      }),
    });

    dispatch({ type: BookingActions.Clear });
  }

  function handleConfirm(event: MouseEvent<HTMLButtonElement>) {
    if (state.arrival === null) {
      dispatch({ type: BookingActions.ToggleArrival });
      event.preventDefault();
      return;
    }
    if (state.departure === null) {
      dispatch({ type: BookingActions.ToggleDeparture });
      event.preventDefault();
      return;
    }
  }

  return (
    <div className={styles.module}>
      <CloseButton className={styles.close} onClick={onClose} />

      <form className={styles.form} onSubmit={handleSubmit}>
        <h2 className={styles.title}>
          {state.type === 'booking' ? 'Бронирование' : 'Расчет стоимости'}
        </h2>

        <DatePicker
          className={styles.arrival}
          name="Дата приезда"
          value={state.arrival}
          isOpen={state.ui.arrivalIsOpen}
          onToggle={() => dispatch({ type: BookingActions.ToggleArrival })}
          onClickDay={(day) => {
            dispatch({ type: BookingActions.SetArrival, payload: day });
            dispatch({ type: BookingActions.ToggleArrival });
          }}
          disabledDaysBefore={new Date()}
        />

        <DatePicker
          className={styles.departure}
          name="Дата Отъезда"
          value={state.departure}
          isOpen={state.ui.departureIsOpen}
          onToggle={() => dispatch({ type: BookingActions.ToggleDeparture })}
          onClickDay={(day) => {
            dispatch({ type: BookingActions.SetDeparture, payload: day });
            dispatch({ type: BookingActions.ToggleDeparture });
          }}
          disabledDaysBefore={new Date()}
        />

        <Select
          className={styles.adults}
          title="Кол-во взрослых"
          paddingLeft={150}
          options={getAdultOptions()}
          onChange={(event) =>
            dispatch({
              type: BookingActions.SetAdults,
              payload: +event.target.value,
            })
          }
          value={state.adults}
        />

        <Select
          className={styles.baby}
          title="Кол-во детей"
          paddingLeft={122}
          options={getBabyOptions()}
          onChange={(event) =>
            dispatch({
              type: BookingActions.SetBabies,
              payload: +event.target.value,
            })
          }
          value={state.babies.length}
        />

        {state.babies.length > 0 && (
          <Babies
            className={styles.babies}
            isOpen={state.ui.babiesIsOpen}
            babies={state.babies}
            onToggle={() => dispatch({ type: BookingActions.ToggleBabies })}
            onChangeBaby={(idx, age) =>
              dispatch({
                type: BookingActions.ChangeBaby,
                payload: { idx, age },
              })
            }
          />
        )}

        <Input
          className={styles.name}
          placeholder="Имя"
          value={state.name}
          onChange={(event) =>
            dispatch({
              type: BookingActions.SetName,
              payload: event.target.value,
            })
          }
        />

        <Input
          className={styles.phone}
          placeholder="Телефон"
          type="tel"
          pattern="^\+?[0-9()\- ]+"
          required
          value={state.phone}
          onChange={(event) =>
            dispatch({
              type: BookingActions.SetPhone,
              payload: event.target.value,
            })
          }
        />

        <Policy
          className={styles.policy}
          required
          checked={state.policyIsChecked}
          onToggle={() => dispatch({ type: BookingActions.TogglePolicy })}
          onClickButton={() =>
            appDispatch({ type: AppActions.ToggleTextModal, payload: 'policy' })
          }
        />

        <button className={styles.button} onClick={handleConfirm}>
          Отправить
        </button>
      </form>
    </div>
  );
}
